import React, { useEffect, useState, useContext } from "react";
import { useForm } from "../../../../../hooks/form-hook";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../../context/auth-context";
import { TeacherContext } from "../../../../../context/teacher-context";
import { ContractorContext } from "../../../../../context/contractor-context";
import { useWindowDimensions } from "../../../../../hooks/window-dimensions-hook";
import { isAlphanumeric, isRequired } from "../../../../../utils/validators";
import sha256 from 'crypto-js/sha256';

// Components
import ModalHeader from "../../../../ModalHeader/ModalHeader";
import ListHeader from "../../../../ListHeader/ListHeader";
import InvoicesTable from "../InvoicesTable/InvoicesTable";
import Input from "../../../../Input/Input";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../../../HttpMessagePrompt/HttpMessagePrompt";

// Icones
import pdfIcon from "../../../../../assets/images/file-pdf.svg";

//Styles
import styles from "./GenerateInvoice.module.scss";
import CheckBox from "../../../../CheckBox/CheckBox";

const GenerateInvoice = (props) => {

    // Authentication context
    const auth = useContext(AuthContext);

    // App context
    const context = useContext(TeacherContext);

    const contractorContext = useContext(ContractorContext)

    // Backend Request Hook
    const { isLoading, error, okHttp, sendRequest } = useHttpRequest();

    // Window Size Hook
    const { width } = useWindowDimensions();

    // Invoice total
    const [invoiceTotal, setInvoiceTotal] = useState(0);

    // Invoice checked amounts for sum
    const [invoiceAmounts, setInvoiceAmounts] = useState([]);

    // List of Ids to invoice
    const [idList, setIdList] = useState([]);
    
    // List of Ids to invoice
    const [idsToUpdate, setIdsToUpdate] = useState([]);
    
    // Backend Payment Date
    const [paymentDate, setPaymentDate] = useState();
    
    // Next possible invoicing date
    const [nextInvoiceDate, setNextInvoiceDate] = useState();

    // File name preview
    const [filename, setFilename] = useState();

    // useState | useContext
    const [file, setFiles] = useState();

    // Use state de la validation
    const [fileIsValid, setFileIsValid] = useState(false);

    const [isSubmitable, setIsSubmitable] = useState(false);

    // Choix du campus
    const [campus, setCampus] = useState(false);

    // Form State
    const [formState, inputHandler] = useForm(
        {
            campusSelector: {
                value: null,
                isValid: false,
            },
            invoiceNumber: {
                value: "",
                isValid: false,
            },
            invoiceDate: {
                value: "",
                isValid: false,
            },
            file: {
                value: null,
                isValid: false,
            }
        },
        false
    );

    const placeCampus = useState([
        {
            default: "",
            label: "0",
        },
        {
            default: "Campus de Paris Ouest",
            label: "74",
        },
        {
            default: "Campus de Lyon",
            label: "75",
        },
        {
            default: "Campus E-Learning",
            label: "76",
        },
        {
            default: "Campus de Bordeaux",
            label: "77",
        },
        {
            default: "Campus de Aix/Marseille",
            label: "78",
        },
        {
            default: "Campus de Lille",
            label: "79",
        },
        {
            default: "Campus de Nantes",
            label: "80",
        },
        {
            default: "Campus de Montpellier",
            label: "82",
        },
        {
            default: "Campus de Toulouse",
            label: "83",
        },
        {
            default: "Campus de Rennes",
            label: "84",
        },
        {
            default: "Campus de Strasbourg",
            label: "85",
        },
        {
            default: "Campus de Paris Est",
            label: "86",
        },
    ]);

    // Function helper to format campus list for invoicing

    const teacherCampusTab = [];
    if (context.teacherCampuses) {
        Object.entries(context.teacherCampuses).map(([key, value]) => {
            // We look at teacherCurrentAccountYear to filter on its values
            if (key === context.teacherCurrentAccountYear) {
                placeCampus[0].forEach(campus => {
                    for (const [key, valueOfCampus] of Object.entries(value)) {
                        if (valueOfCampus === campus.label)
                        teacherCampusTab.push(valueOfCampus);
                    }
                })
            }
        })
    }

    const filterTeacherCampusTab = (element) => {
        if (teacherCampusTab.includes(element.label) || element.label == "0") return true
        else return false;
    }

    const campusFormatForInvoicing = placeCampus[0].filter(filterTeacherCampusTab);

    // Function Helper
    const formatDate = (date) => {
        const datePart = date.split("-");
        return `${datePart[2]}/${datePart[1]}/${datePart[0]}`;
    };

    // Function Helper
    const removeId = (arr, value) => {
        const index = arr.indexOf(value);
        if (index > -1) {
          arr.splice(index, 1);
        }
        return arr;
    }

    const addAmountHandler = (event, isChecked) => {
        const checkBoxId = event.currentTarget.id;
        const coursesIds = event.currentTarget.value.split(",");
        
        const courseValue = parseFloat(props.realizedCourses[checkBoxId]["total"]).toFixed(2) / 100;

        if (isChecked) {
            let newAmount = {
                id: checkBoxId,
                value: courseValue,
            };
            setInvoiceAmounts(prevAmounts => [...prevAmounts, newAmount]);
            setIdsToUpdate(prevUpdateIds => [...prevUpdateIds, parseInt(checkBoxId, 10)]);
            setIdList(prevIds => prevIds.concat(coursesIds));
        } else {
            if (invoiceAmounts.length >= 1) {
                setInvoiceAmounts(prevAmounts => prevAmounts.filter(amount => amount.id !== checkBoxId));
                setIdsToUpdate(prevUpdateIds => prevUpdateIds.filter(id => id != checkBoxId));
                setIdList(prevIds => {
                    coursesIds.forEach(courseId => removeId(prevIds, courseId));   
                    return prevIds;
                })
            } else {
                setInvoiceAmounts(0);
                setIdList([]);
            }
        }
    };

    const postInvoiceListHandler = async (event) => {
        event.preventDefault();

        // On vient forcer la validation du formState Campus car si l'intervenant n'a pas plusieurs campus d'intervention alors on ne tient pas compte de ce formState.
        if (!formState.inputs.campusSelector.isValid) {
            formState.inputs.campusSelector.isValid = true;
            formState.isValid = true;
        }

        // Prevent form if not completed
        if (!formState.isValid) {
            console.log("formState invalide");
            return;
        }
        if (invoiceTotal === 0) {
            console.log("invoice Total invalid");
            return;
        }
        
        const formData = new FormData();
        formData.append("account_id", auth.userType === "contractor" ? contractorContext.contractorMainAccount : context.teacherSelectedAccount);
        formData.append("ids", idList);
        formData.append("campusId", props.campus ? props.campus : (auth.userType === "contractor" ? contractorContext.contractorPlaceId : context.teacherPlaceId));
        formData.append("amount", invoiceTotal);
        formData.append("invoice_date", formState.inputs.invoiceDate.value);
        formData.append("invoice_number", formState.inputs.invoiceNumber.value);
        // formData.append("invoiced", "Oui");
        formData.append("file", file);
        formData.append("fileName", file.name);

        try {
            let url;
            if (auth.userType === "contractor") url = `${process.env.REACT_APP_API_HOST}/invoicing/create-invoice?contractor=true`;
            else url = `${process.env.REACT_APP_API_HOST}/invoicing/create-invoice`;

            const response = await sendRequest(url, "POST", formData, {
                Authorization: "Bearer " + auth.token,
            });
            console.log(response)
            const { payment_date, next_invoice_date } = response;

            let invoicedCourses = [];
            let newRealizedCourses = [];

            // Update Course State
            props.setRealizedCourses(prevRealizedCourses => {
                prevRealizedCourses.forEach((course, index) => {
                    if (idsToUpdate.includes(index)) invoicedCourses.push({ ...course, status: "invoiced" });
                    else newRealizedCourses.push(course);
                });
                return newRealizedCourses;
            });

            // Update User States
            setPaymentDate(formatDate(payment_date));
            setNextInvoiceDate(formatDate(next_invoice_date));
            props.setInvoicedCourses(invoicedCourses);
            props.setNewInvoicingDate(next_invoice_date);
        } catch (err) {
            console.error(err);
        }
    };

      // Fontion qui prend l'image
    const pickedFileHandler = (event) => {
        
        // Set File States
        if (event.target.files && event.target.files.length === 1) {
            setFilename(event.target.files[0].name);
            setFiles(event.target.files[0]);
            setFileIsValid(true);
            
        } else {
            setFileIsValid(false);
            setFilename("");
        }
        inputHandler("file", event.target.files[0], true)
    };

    const calculateTotal = (array) => {
        let total = 0;
        array.forEach((amount) => {
            total = total + amount.value;
        });
        return total;
    };

    // useEffect(() => {
    //     let courses;
    //     if (campus) {
    //         console.log(campus);
    //         courses = props.realizedCourses.filter(course => course.place_id == props.campus);
    //     } else courses = props.realizedCourses;

    //     console.log(courses);
    //     props.setRealizedCourses(courses);
    // }, [campus])

    // After all information is loaded and treated, calculates total
    useEffect(() => {
        if (invoiceAmounts.length !== 0) {
            const total = calculateTotal(invoiceAmounts);
            setInvoiceTotal(total);
        } else setInvoiceTotal(0);
    }, [invoiceAmounts]);

    let closeBtn = (
        <ActionBtn
            id="close_attendance_list"
            btnType="contained"
            btnStyle={styles.btnStyles}
            activeBtnStyle={styles.btn_active}
            btnText="Fermer la fenêtre"
            textStyle={styles.btn_text}
            onClick={props.modalFunction}
        />
    );

    return (
        <article className={styles.modal_container}>
            {props.realizedCourses && (
                <>
                    <ModalHeader
                        headerColor={styles.banner_color}
                        headerTitle="Intervention à facturer"
                        closeBtn={props.modalFunction}
                    />
                </>
            )}
                <section className={styles.wrapper}>
                    {error && <HttpMessagePrompt error={error} btn={closeBtn} />}
                    {okHttp && paymentDate && nextInvoiceDate && (
                        <HttpMessagePrompt
                        error={false}
                        btn={closeBtn}
                        message={`Votre facture a été bien enregistrée. Un virement sera effectué sur votre compte, sous condition de vérification, à partir du ${paymentDate}.`}
                        secondaryMessage={
                            <>
                            <div>Votre espace de facturation sera à nouveau disponible dans 3 semaines, soit le <span style={{fontSize: "1.8rem", fontWeight: "500"}}>{nextInvoiceDate}</span><br /><br /></div>
                            <div style={{textAlign: "left"}}>
                                Le règlement s'effectue à M+1, le 1er ou le 15 de chaque mois. Cela dépend de la date d'enregistrement de votre facture. Par exemple :<br /><br />
                                Date enregistrement de votre facture le 24/10, le règlement sera effectué le 01/12<br />
                                Date enregistrement de votre facture le 10/11, le règlement sera effectué le 15/12<br />
                            </div>
                            </>
                        }
                        />
                    )}
                    {isLoading && (
                        <div className="spinner">
                            <LoadingSpinner textColor={styles.spinner_text_color} />
                        </div>
                    )}
                    {!isLoading && props.realizedCourses && !okHttp && !error && (
                        <ListHeader
                            title="Liste des cours à facturer"
                            message="Veuillez cocher les cours réalisés et renseigner le numéro et date de facture pour générer une facture groupée."
                        />
                    )}
                    {!isLoading && props.realizedCourses && !okHttp && !error && (
                        <div className={styles.overflow}> 
                            <InvoicesTable
                                realizedCourses={props.realizedCourses}
                                campus={campus}
                                selfEmployed={props.selfEmployed}
                                filter="realized"
                                onChange={addAmountHandler}
                                headerLabels={{
                                    assignment: "Matière",
                                    teacherName: "Intervenant",
                                    date: "Date du cours",
                                    length: "Créneau",
                                    duration: "Durée",
                                    rate: "Taux",
                                    total: "Total",
                                }}
                            />
                            <p className={styles.note}>Note : La date de facture doit être identique à la date inscrite sur votre facture. Merci de vérifier que le numéro de la facture émise ne correspond pas à une facture précédente. En cas de doublon de numéro de facture, celle-ci ne pourra être traitée.</p>
                            <form className={styles.form} id="send-invoice-form" onSubmit={postInvoiceListHandler}>
                                <div className={styles.form_wrapper}>
                                    <div className={styles.form_number}>
                                        <Input
                                            typeOfInput="input"
                                            id="invoiceNumber"
                                            name="invoiceNumber"
                                            type="text"
                                            label="N° de la facture émise *"
                                            placeholder="ex: 123-2021.11.05"
                                            maxLength="45"
                                            validators={[isRequired(), isAlphanumeric()]}
                                            errorText="Veuillez saisir le numéro de la facture émise sans caractères spéciaux"
                                            initialValue={formState.inputs.invoiceNumber.value}
                                            initialValid={formState.inputs.invoiceNumber.isValid}
                                            onInput={inputHandler}
                                        />
                                    </div>
                                    <div className={styles.form_date}>
                                        <Input
                                            typeOfInput="input"
                                            id="invoiceDate"
                                            name="invoiceDate"
                                            type="date"
                                            label="Date de la facture émise *"
                                            placeholder="30/01/2020"
                                            maxLength="10"
                                            validators={[isRequired()]}
                                            errorText="Veuillez saisir la date de la facture émise"
                                            initialValue={formState.inputs.invoiceDate.value}
                                            initialValid={formState.inputs.invoiceDate.isValid}
                                            onInput={inputHandler}
                                        />
                                    </div>
                                    {!isSubmitable && (
                                        <div className={styles.isSubmitable}>
                                            <CheckBox
                                            value={isSubmitable}
                                            onChange={() => {
                                                setIsSubmitable(true);
                                            }}
                                        />
                                        <p style={{ marginLeft:"5%" }}>
                                        Je confirme que le montant indiqué sur la facture correspond au montant indiqué par la plateforme. (Pour rappel, seules les créneaux dont le statut est réalisé sont facturables)
                                        </p>
                                        </div>
                                    )}
                                    {isSubmitable && (
                                    <div className={styles.logo}>
                                        <label htmlFor="upload-button" className={styles.upload_btn}>
                                            <div className={styles.pdfIcon} >
                                                <img src={pdfIcon} alt="" />
                                            </div>
                                            <div className={styles.pdfBtn}>
                                                <span className={styles.pdfBtn_text}>{filename}</span>
                                            </div>
                                            <div className={styles.pdfBtn}>
                                                <span className={styles.pdfBtn_text}>Ajouter la facture</span>
                                            </div>
                                        </label>
                                        <input
                                            name="file"
                                            type="file"
                                            accept=".jpg, .jpeg, .gif, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
                                            id="upload-button"
                                            style={{ display: "none"}}
                                            onChange={pickedFileHandler}
                                        />
                                    </div>
                                    )}
                                </div>
                                {(props.selfEmployed || auth.userRole === "contractor") && (
                                    <div className={styles.total_wrapper}>
                                        <p className={styles.total_label}>Total TTC :</p>
                                        <p className={styles.total_amount}>{invoiceTotal} €</p>
                                    </div>
                                )}
                            </form>
                            
                            {(!context.teacherCurrentMultipleCampuses && auth.userType !== "contractor") && (
                                <p className={styles.disclaimer}>ATTENTION ! : En soumettant votre facture, cet espace sera fermé pendant 3 semaines.</p>
                            )}
                            <div style={{height: "3.8rem", marginBottom: "8rem"}}>
                                <ActionBtn
                                    id="post_invoice_list"
                                    form="send-invoice-form"
                                    type="submit"
                                    btnType="contained"
                                    btnStyle={styles.btnStyles}
                                    activeBtnStyle={isSubmitable ? styles.btn_active : styles.btnStylesDisabled}
                                    btnText="Soumettre"
                                    textStyle={styles.btn_text}
                                />
                            </div>
                        </div>
                    )}
                </section>
        </article>
    );
};

export default GenerateInvoice;
